






























































import {Component, Vue} from "vue-property-decorator";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";

@Component({
  name: 'Profile',
})
export default class Profile extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected dropdownOpen = false;
  protected database = new IndexDBClient();

  protected myProfile = {};
  protected firstAndSurname;

  get profile() {
    this.database.users.then( (user: any) => {
      this.myProfile = user[0];
    })
    return this.myProfile;
  }

  get fullName(): string{
    const userProfile: any =  this.profile;
    return userProfile.name +" "+ userProfile.surname;
  }
}
